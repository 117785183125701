import { images } from "../assets/img";

export const NAIL_SHOP_INFO = {
  ggMapUrl: "https://maps.app.goo.gl/DBKNaWcqMzRtQRsm9",
  address: "19 Turners Hill, Cheshunt, Waltham Cross EN8 8NJ, UK",
  ggMapIframeUrl:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1118.4777549976275!2d-0.035175697549535735!3d51.699941297519274!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48762039ef0762ef%3A0xa54797726fa07a15!2s19%20Turners%20Hill%2C%20Cheshunt%2C%20Waltham%20Cross%20EN8%208NJ%2C%20UK!5e1!3m2!1sen!2s!4v1734085130552!5m2!1sen!2s" ,
  email: "kevinnails2024@gmail.com",
  tel: "+441992937288",
  phone: "+44 199 293 7288",
  facebookId:"411793872023888",
  instagramName: "kevin_nails_2024",
  name: "Kevin Nails",
  bookingUrl: "https://kevinnails.setmore.com/",
  openingTime:{
    openTime:{weekdays: "MONDAY - SATURDAY", time:"09:30 - 18:30"},
    closeTime:{weekdays: "SUNDAY & Bank Holiday", time:"Closed"}
  },
};

NAIL_SHOP_INFO.instagram = `https://www.instagram.com/${NAIL_SHOP_INFO.instagramName}`;
NAIL_SHOP_INFO.instagramChatUrl = `https://ig.me/m/${NAIL_SHOP_INFO.instagramName}`;
NAIL_SHOP_INFO.facebook = `https://www.facebook.com/profile.php?id=${NAIL_SHOP_INFO.facebookId}`
NAIL_SHOP_INFO.facebookMessengerUrl = `http://m.me/${NAIL_SHOP_INFO.facebookId}`

export const SOLCO_INFO = {
  facebook: "https://www.facebook.com/solcoagency.marketing",
};

export const SERVICES = [
  {
    name: "Manicures",
    description:
      "Experience a manicure that focuses on precision and care, using premium products to enhance the natural beauty of your nails. Our expert technicians ensure that your nails are treated with the utmost attention and ready to impress.",

    image: images.nailService,
  },
  {
    name: "Pedicures",
    description:
      "Indulge in a pedicure that shapes, smooths, and cares for your nails and toes. Enjoy exfoliation with a refreshing cucumber or peppermint mask, followed by a soothing cream application, and finish with the perfect color of your choice.",
    image: images.nailService2,
  },
  {
    name: "Our Technicians",
    description:
      "Our highly qualified team of nail technicians will take care your nails the ins and outs during your experiences at our nails bar. We always welcome you with a warm smile and make you feel at home.",
    image: images.nailService3,
  },
  {
    name: "Nails Art",
    description:
      "From classic to outstanding, our talented technicians can advise you the bespoke nail art of your imagine. Come here and enjoy your amazing nails art at our local shop",
    image: images.nailService4,
  },
];

export const LANGUAGE_ID = {
  vi: "vi",
  en: "en",
};

export const CUSTOMER_FEEDBACK = [
  {
    "name": "Emily Harper",
    "feedback":
      "“The service was fantastic, and the staff were so professional and welcoming. I loved how my nails turned out—just perfect!”",
    "avatar": "https://i.imgur.com/sU1i6Sk.png"
  },
  {
    "name": "Sophia Brooks",
    "feedback":
      "“Such a relaxing experience! The team really paid attention to every detail, and I left feeling so happy with the results.”",
    "avatar": "https://i.imgur.com/Lq7YQ1o.png"
  },
  {
    "name": "Chloe Bennett",
    "feedback":
      "“Professional, clean, and efficient. I’m so pleased with the results! Would definitely recommend this place to others.”",
    "avatar": "https://i.imgur.com/HDBW1Xg.png"
  },
  {
    "name": "Olivia Carter",
    "feedback":
      "“A wonderful experience! The staff were attentive, and the results exceeded my expectations. I’ll definitely be back.”",
    "avatar": "https://i.imgur.com/RsFFmiS.png"
  },
  {
    "name": "Hannah Collins",
    "feedback":
      "“Great value for the quality of work! The attention to detail is impressive, and the results were flawless. Highly recommend.”",
    "avatar": "https://i.imgur.com/Ds2X7zK.png"
  },
  {
    "name": "Grace Taylor",
    "feedback":
      "“I had an amazing experience! The team made sure I was comfortable and satisfied every step of the way. My nails look beautiful.”",
    "avatar": "https://i.imgur.com/KxhUFKM.jpg"
  },
  {
    "name": "Madison Green",
    "feedback":
      "“Top-notch service from start to finish! The results were stunning, and I loved the friendly atmosphere. I’ll be recommending it to everyone.”",
    "avatar": "https://i.imgur.com/kI3rsKc.jpg"
  },
  {
    "name": "Ava Mitchell",
    "feedback":
      "“The team did a fantastic job! They’re skilled, attentive, and ensured everything was perfect. My nails look amazing!”",
    "avatar": "https://i.imgur.com/92gXkP8.jpg"
  }
]


export const MENU_HEADER = [
  { name: "Home", navigationKey: "home" },
  { name: "About Us", navigationKey: "about-us" },
  { name: "Services", navigationKey: "services" },
  { name: "Booking", navigationKey: NAIL_SHOP_INFO.bookingUrl },
  // { name: "Contacts", navigationKey: "contactsRef" },
  { name: "Contact", navigationKey: "contact-us" },
  { name: "Policy", navigationKey: "policy" },
];
